import { useMutation, useQuery } from '@apollo/client'
import { Modal, Select, notification, useMessage } from '@faceup/ui-base'
import { useEffect, useState } from 'react'
import { query as institutionDetail } from '../Pages/InstitutionDetail'
import { graphql } from '../__generated__'

type Props = {
  motherId: string
  partnerId?: string
  visible: boolean
  setIsLoading: (loading: boolean) => void
  hide: () => void
}

const query = {
  LinkCompanyToPartnerModalPartnersQuery: graphql(`
    query LinkCompanyToPartnerModalPartnersQuery {
      partners(page: 0, rowsPerPage: 500, verificationStatus: [Approved]) {
        totalCount
        items {
          id
          name
        }
      }
    }
  `),
}

const mutation = {
  LinkCompanyToPartner: graphql(`
    mutation linkCompanyToPartnerMutation($input: LinkCompanyToPartnerInput!) {
      linkCompanyToPartner(input: $input) {
        success
      }
    }
  `),
}

const LinkCompanyToPartnerModal = ({ setIsLoading, visible, hide, motherId, partnerId }: Props) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState<string>()
  const message = useMessage()

  const { data, loading } = useQuery(query.LinkCompanyToPartnerModalPartnersQuery, {
    skip: !visible,
  })

  const [linkCompanyToPartner, { loading: linkingToPartner }] = useMutation(
    mutation.LinkCompanyToPartner,
    {
      refetchQueries: [institutionDetail.InstitutionQuery],
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      onCompleted: () => {
        message.success('Company linked')
      },
    }
  )

  useEffect(() => {
    setIsLoading(linkingToPartner)
  }, [linkingToPartner, setIsLoading])

  const partners = data?.partners?.items ?? []

  const onOk = async () => {
    if (selectedPartnerId) {
      await linkCompanyToPartner({
        variables: {
          input: {
            partnerId: selectedPartnerId,
            motherId,
          },
        },
      })
    }
    hide()
  }

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      onOk={onOk}
      confirmLoading={loading}
      title='Link company to partner'
    >
      <Select
        loading={loading}
        placeholder='Select partner'
        defaultValue={partnerId}
        value={selectedPartnerId}
        onChange={partnerId => setSelectedPartnerId(partnerId)}
        options={partners.map(({ id, name }) => ({ label: name, value: id }))}
      />
    </Modal>
  )
}

export default LinkCompanyToPartnerModal
