import { useMutation } from '@apollo/client'
import { getCurrentEncryptionVersion, prehashPassword, savePersonalKeys } from '@faceup/crypto'
import { useLocation } from '@faceup/router'
import { UserLoginType } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useState } from 'react'
import { graphql } from '../../__generated__'
import Auth from '../../utils/auth'
import useRegion from '../../utils/useRegion'
import type { FormType } from './Login'

const mutation = {
  preLogin: graphql(`
    mutation UserPreLoginMutation($input: UserPreLoginInput!) {
      userPreLogin(input: $input) {
        version
        salt
      }
    }
  `),
  login: graphql(`
    mutation UserLoginMutation($input: UserLoginInput!) {
      userLogin(input: $input) {
        token
        country
        userType
        publicKey
        privateKey
        nonce
        version
      }
    }
  `),
}

const useLogin = (setLoading: (isLoading: boolean) => void) => {
  const [code, setCode] = useState({ value: '', error: false })
  const [email, setEmail] = useState({ value: '', error: false })
  const [password, setPassword] = useState({ value: '', error: false })
  const [formType, setFormType] = useState<FormType>('login')
  const { discoverByEmail } = useRegion()

  const location = useLocation()
  const [serverLoginFormError, setServerLoginFormError] = useState(false)

  const navigate = () => {
    const previousLocationUrl = location?.state?.from
    const newLocation = previousLocationUrl || `/${location.search}${location.hash}`
    window.location.replace(newLocation)
    // if there is hash in url, we need to reload the page, because changing hash does not trigger page reload
    if (previousLocationUrl?.includes('#') || location.hash) {
      window.location.reload()
    }
  }

  const [loginFirstPhase] = useMutation(mutation.preLogin, {
    variables: {
      input: {
        loginType: UserLoginType.FuAdmin,
        email: email.value.trim(),
      },
    },
    onError: e => {
      setLoading(false)
      console.error(e)
      setServerLoginFormError(true)
    },
    onCompleted: ({ userPreLogin }) => {
      if (userPreLogin) {
        processFirstPhase({ userPreLogin })
      }
    },
  })

  const startLogin = async () => {
    await discoverByEmail(email.value.trim())

    return loginFirstPhase()
  }

  const processFirstPhase = async ({ userPreLogin }: ResultOf<typeof mutation.preLogin>) => {
    const version = (userPreLogin?.version ?? 1) as 1 | 2
    const salt = userPreLogin?.salt ?? ''
    const prehashedPassword = await prehashPassword({
      password: password.value.trim(),
      salt,
      version,
    })

    if (prehashedPassword.isErr()) {
      setLoading(false)
      console.error(prehashedPassword.error)
      return
    }

    const { passwordKeyPrehash, passwordKey } = prehashedPassword.value

    loginSecondPhase({
      variables: {
        input: {
          email: email.value.trim(),
          passwordPrehash: passwordKeyPrehash,
          rememberMe: false,
          loginType: UserLoginType.FuAdmin,
          version,

          ...(formType === '2fa' && { code: code.value.trim() }),
        },
      },
      onCompleted: userLogin => {
        if (userLogin) {
          processSecondPhase(userLogin, passwordKey)
        }
      },
    })
  }

  const processSecondPhase = async (
    { userLogin }: ResultOf<typeof mutation.login>,
    passwordKey: string
  ) => {
    // > After login I should -> set jwt token + refetch viewer
    // > It's easier to redirect and reload page

    const defaultVersion = getCurrentEncryptionVersion()
    if (!userLogin?.token) {
      return
    }

    await Auth.setJwt({
      jwt: userLogin.token,
      persistent: false,
    })

    await savePersonalKeys({
      publicKey: userLogin.publicKey ?? '',
      privateKey: userLogin.privateKey ?? '',
      nonce: userLogin.nonce ?? '',
      passwordKey,
      rememberMe: false,
      version: userLogin.version ?? defaultVersion,
    })

    // maybe the consuming component should decide what to do after the process is done
    navigate()
  }

  const [loginSecondPhase] = useMutation(mutation.login, {
    onError: e => {
      setLoading(false)
      if (e.graphQLErrors[0]?.message === 'Missing 2FA code') {
        setFormType('2fa')
      } else if (formType === '2fa') {
        setCode({ ...code, error: true })
      } else {
        console.error(e)
        setServerLoginFormError(true)
      }
    },
  })

  return {
    serverLoginFormError,
    login: () => {
      setLoading(true)
      startLogin()
    },
    code,
    setCode,
    email,
    setEmail,
    password,
    setPassword,
    formType,
    setFormType,
  }
}

export default useLogin
