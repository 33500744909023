import { type ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { repackReportKey } from '@faceup/crypto'
import { useCryptoErrorHandler } from '@faceup/report'
import { useNavigate, useSearchParams } from '@faceup/router'
import { Modal, notification, useMessage } from '@faceup/ui-base'
import type { Institution } from '@faceup/utils'
import { useEffect, useState } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  GrantAccessActionModal_user: graphql(`
    fragment GrantAccessActionModal_user on UserInterface {
      id
      name
      keys {
        id
        publicKey
      }
    }
  `),
}

const query = {
  GrantAccessCompanyReports: graphql(`
    query GrantAccessCompanyReportsQuery($motherId: UUID!, $memberId: UUID!) {
      grantAccessCompanyReports(motherId: $motherId, memberId: $memberId) {
        id
        encryptionKey
        category {
          id
          name
        }
        company {
          id
          organizationalUnitName
        }
      }
    }
  `),
}

const mutations = {
  GrantCompanyReportAccess: graphql(`
    mutation GrantCompanyReportAccessMutation(
      $input: GrantCompanyReportAccessInput!
    ) {
      grantCompanyReportAccess(input: $input) {
        success
      }
    }
  `),
}

type Props = {
  close: () => void
  visible: boolean
  users: FragmentType<typeof fragments.GrantAccessActionModal_user>[]
  institution: Institution
  institutionId: string
}

const GrantAccessActionModal = ({
  visible,
  close,
  users: _users,
  institution,
  institutionId,
}: Props) => {
  const users = getFragmentData(fragments.GrantAccessActionModal_user, _users)
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const handleError = useCryptoErrorHandler()
  const message = useMessage()

  const [userId, setUserId] = useState('')
  const recipient = users.find(user => user?.id === userId)

  const onGrantAccessError = (error: ApolloError) => {
    const errorMessage = error.graphQLErrors?.[0]?.message ?? ''
    if (errorMessage === 'Report access was already given.') {
      message.success('Access already given')
      resetModal()
      close()
    } else {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    }
  }

  const onError = (error: ApolloError) => {
    console.error(error)
    notification.error({
      message: 'GQL Error',
      description: error.message,
    })
  }

  const [grantCompanyReportAccess, { loading: loadingCompanyReportGrantAccess }] = useMutation(
    mutations.GrantCompanyReportAccess,
    {
      onCompleted: ({ grantCompanyReportAccess }) => {
        const success = grantCompanyReportAccess?.success ?? false
        if (success) {
          message.success(`Access granted to ${recipient?.name ?? 'unknown'}`)
          resetModal()
          close()
        }
      },
      onError: onGrantAccessError,
    }
  )

  const [fetchCompanyOwnerKey, { loading: loadingCompanyFetchOwner, data: companyData }] =
    useLazyQuery(query.GrantAccessCompanyReports, { onError })

  const loading = loadingCompanyFetchOwner || loadingCompanyReportGrantAccess

  const resetModal = () => {
    setUserId('')

    navigate(routes => routes.dashboard())
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(institution):
  useEffect(() => {
    const queryUserId = params.get('userId') ?? ''

    if (!queryUserId || !visible) {
      return
    }

    setUserId(queryUserId)

    fetchCompanyOwnerKey({
      variables: {
        motherId: institutionId,
        memberId: queryUserId,
      },
    })
  }, [fetchCompanyOwnerKey, visible, params, institution, institutionId])

  const companyReports = companyData?.grantAccessCompanyReports ?? []
  const categories = companyReports?.map(report => report?.category?.name) ?? []
  const companies = companyReports?.map(report => report?.company?.organizationalUnitName) ?? []

  return (
    <Modal
      visible={visible}
      okText='Ok'
      cancelText='Cancel'
      onOk={async () => {
        const recipientKeys = await Promise.all(
          companyReports.map(async report => {
            const reportKey = await repackReportKey(
              report?.encryptionKey ?? '',
              recipient?.keys?.publicKey ?? ''
            )

            if (reportKey.isErr()) {
              handleError(reportKey.error.message)
              return {
                reportId: report?.id ?? '',
                value: '',
              }
            }

            return {
              reportId: report?.id ?? '',
              value: reportKey.value ?? '',
            }
          })
        )

        if (recipientKeys.some(key => !key.value)) {
          return
        }

        await grantCompanyReportAccess({
          variables: {
            input: {
              motherId: institutionId,
              memberId: userId,
              recipientKeys,
            },
          },
        })
      }}
      confirmLoading={loading}
      onCancel={() => {
        resetModal()
        close()
      }}
      title={`Grant access to ${recipient?.name ?? ''}`}
    >
      Request for <b>{recipient?.name ?? ''}</b>, <b>{categories.join(', ')}</b>, in{' '}
      <b>{companies.join(', ')}</b>
    </Modal>
  )
}

export default GrantAccessActionModal
