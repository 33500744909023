import { FrontendPermissionType } from '@faceup/utils'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  PermissionText_permission: graphql(`
    fragment PermissionText_permission on Permission {
      id
      type
      additionalData {
        id
        categoryIds
      }
    }
  `),
}

type Props = {
  allCategoriesCount?: number
  permission: FragmentType<typeof fragments.PermissionText_permission> | null
  isShortened?: boolean
}

const PermissionText = ({
  allCategoriesCount,
  permission: _permission,
  isShortened = false,
}: Props) => {
  const permission = getFragmentData(fragments.PermissionText_permission, _permission)
  const categories = permission?.additionalData?.categoryIds
  switch (permission?.type) {
    case FrontendPermissionType.SettingsAccess:
      return <>Settings access</>
    case FrontendPermissionType.ReportAccess:
      if (!categories || categories.length === allCategoriesCount) {
        return isShortened ? <>All reports access</> : <>Reports access: All reports access</>
      } else if (categories.length === 0) {
        return isShortened ? <>Only assigned reports</> : <>Reports access: Only assigned reports</>
      }
      return isShortened ? (
        <>
          {categories.length} {categories.length > 1 ? 'Categories' : 'Category'}
        </>
      ) : (
        <>
          Report access: ({categories.length} {categories.length > 1 ? 'Categories' : 'Category'})
        </>
      )

    default:
      return <>{permission?.type}</>
  }
}

export default PermissionText
