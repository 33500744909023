import { UserAvatar } from '@faceup/ui'
import { Dropdown, LogoutOutlined } from '@faceup/ui-base'
import { Group, UnstyledButton } from '@mantine/core'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  HeaderProfile_user: graphql(`
    fragment HeaderProfile_user on UserInterface {
      id
      name
      profileImageUrl
    }
  `),
}

type Props = {
  viewer: FragmentType<typeof fragments.HeaderProfile_user>
  logout: () => void
}

const HeaderProfile = ({ viewer: _viewer, logout }: Props) => {
  const viewer = getFragmentData(fragments.HeaderProfile_user, _viewer)
  const name = viewer.name

  return (
    <Dropdown
      menu={{
        items: [
          {
            icon: <LogoutOutlined />,
            key: 'logout',
            label: 'Logout',
            onClick: logout,
          },
        ],
      }}
      placement='bottomRight'
    >
      <UnstyledButton>
        <Group sx={{ paddingInline: '12px' }} spacing='6px'>
          <UserAvatar user={viewer} />
          {name}
        </Group>
      </UnstyledButton>
    </Dropdown>
  )
}

export default HeaderProfile
