import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Button } from '@faceup/ui'
import {
  Form,
  Input,
  MinusCircleOutlined,
  PlusOutlined,
  notification,
  useMessage,
} from '@faceup/ui-base'
import { Country, Plan, isEmail } from '@faceup/utils'
import { Flex } from '@mantine/core'
import { type MouseEvent, memo, useState } from 'react'
import { graphql } from '../__generated__'

const mutations = {
  SendReportEmailsMutation: graphql(`
    mutation SendReportEmailsMutation($input: SendReportEmailsInput!) {
      sendReportEmails(input: $input) {
        editedReport {
          id
          emailsSent
          company {
            id
            organizationalUnitName
            country
            billing {
              id
              plan
            }

            isVerified
            isPendingVerification
          }
        }
      }
    }
  `),
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

type Props = {
  id: string
  closed: boolean | null
  emailsSent: boolean | null
  company: {
    id?: string | null
    name?: string | null
    isVerified: boolean | null
    isPendingVerification: boolean | null
    country: Country | null
    billing: {
      plan: Plan | null
    } | null
  }
}

const NonRegisteredEmails = memo(({ company, closed, emailsSent, id }: Props) => {
  const [emails, setEmails] = useState<{ id: number; email: string }[]>([])
  const message = useMessage()

  const [sendReportEmails, { loading }] = useMutation(mutations.SendReportEmailsMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => message.success('E-mails sent'),
  })

  // eg do not wait for registration
  const showForm =
    company?.country === Country.Cz && company?.billing?.plan === Plan.Unregistered && !closed

  const validateForm = () => {
    const wrongEmails = emails.filter(({ email }) => !isEmail(email))
    if (wrongEmails.length) {
      notification.error({
        message: 'Following emails are invalid',
        description: wrongEmails.map(({ email }) => email).join(', '),
      })
      return false
    }

    return true
  }

  if (company?.isPendingVerification) {
    return (
      <>
        Institution{' '}
        <Link to={routes => routes.institution({ id: company.id ?? '' })}>{company?.name}</Link> is
        pending verification. Verify it please first
      </>
    )
  }

  if (!showForm) {
    return null
  }

  return (
    <>
      <NonRegisteredSchoolEmailsContainer>
        <NonRegisteredSchoolEmailsAside>
          <NonRegisteredSchoolEmailsHeading>E-mail</NonRegisteredSchoolEmailsHeading>
        </NonRegisteredSchoolEmailsAside>

        <NonRegistredSchoolEmailsContent>
          {emails.map(({ id, email }) => (
            <Form.Item key={id} {...formItemLayout} required>
              <EmailInput
                type='email'
                autoComplete='email'
                placeholder='Add e-mail'
                value={email}
                onChange={({ target: { value } }) =>
                  setEmails(emails.map(email => (email.id === id ? { id, email: value } : email)))
                }
              />
              <DynamicDeleteIcon onClick={() => setEmails(emails.filter(e => id !== e.id))} />
            </Form.Item>
          ))}

          <NonRegistredSchoolEmailsContent>
            <Button
              variant='tertiary'
              isFullWidth
              onClick={() =>
                setEmails([
                  ...emails,
                  {
                    id: (emails.length ? Math.max(...emails.map(e => e.id)) : 0) + 1,
                    email: '',
                  },
                ])
              }
            >
              <PlusOutlined />
              Add e-mail
            </Button>
          </NonRegistredSchoolEmailsContent>
        </NonRegistredSchoolEmailsContent>
      </NonRegisteredSchoolEmailsContainer>
      <Flex
        justify='flex-end'
        sx={{
          marginTop: '20px',
        }}
      >
        <Button
          loading={loading}
          disabled={emails?.length === 0}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()

            if (validateForm()) {
              setEmails([])
              sendReportEmails({
                variables: {
                  input: {
                    reportId: id,
                    emailsForUnregisteredInstitution: emails.map(({ email }) => email),
                  },
                },
              })
            }
          }}
        >
          Send e-mails
          {emailsSent ? (
            <>
              &nbsp;AGAIN <i>(e-mails already sent)</i>
            </>
          ) : null}
        </Button>
      </Flex>
    </>
  )
})

const NonRegisteredSchoolEmailsContainer = styled.div`
  display: flex;
  width: 100%;
`

const NonRegistredSchoolEmailsContent = styled.div`
  width: 90%;
`

const NonRegisteredSchoolEmailsAside = styled.div`
  width: 10%;
`

const NonRegisteredSchoolEmailsHeading = styled.h4`
  margin-top: 10px;
`

const DynamicDeleteIcon = styled(MinusCircleOutlined)`
  height: 100%;
`

const EmailInput = styled(Input)`
  width: 90%;
  margin-right: 8px;
`

NonRegisteredEmails.displayName = NonRegisteredEmails.name

export default NonRegisteredEmails
