import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Card, ContentLayout, Switch, TableEnhanced } from '@faceup/ui'
import { Checkbox, Col, Pagination, Row, Space, notification } from '@faceup/ui-base'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment, { type MomentInput } from 'moment-timezone'
import { useState } from 'react'
import { graphql } from '../__generated__'
import { DATE_FORMAT, ROWS_PER_PAGE } from '../constants'

const query = {
  ReportsTableQuery: graphql(`
    query ReportsTableQuery(
      $page: Int!
      $rowsPerPage: Int!
      $emailsSent: Boolean!
    ) {
      unregisteredCompanyReports(
        page: $page
        rowsPerPage: $rowsPerPage
        emailsSent: $emailsSent
      ) {
        totalCount
        items {
          id
          emailsSent
          closed
          createdAt
        }
      }
      psychologistReports(
        page: $page
        rowsPerPage: $rowsPerPage
        emailsSent: $emailsSent
      ) {
        id
        victimName
        moreInfo
      }
    }
  `),
}

type ReportsTableQuery = ResultOf<typeof query.ReportsTableQuery>

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '70%',
    render: ([reportId, name]: [string, string]) => (
      <Link to={routes => routes.report({ id: reportId })}>{name}</Link>
    ),
  },
  {
    title: 'Emails sent',
    dataIndex: 'emailsSent',
    width: '10%',
    render: (emailsSent: boolean) => <Checkbox disabled defaultChecked={emailsSent} />,
  },
  {
    title: 'Created date',
    dataIndex: 'createdAt',
    width: '10%',
    render: (createdAt: MomentInput) => createdAt && moment(createdAt).format(DATE_FORMAT),
  },
]

const Reports = () => {
  const [page, setPage] = useState(1)
  const [emailsSent, setEmailsSent] = useState(false)
  const [reports, setReports] = useState<Awaited<ReturnType<typeof makeTableRow>>[]>([])

  const { loading, data } = useQuery(query.ReportsTableQuery, {
    variables: {
      page: page - 1,
      rowsPerPage: ROWS_PER_PAGE,
      emailsSent,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: async data => {
      const reportItems = data?.unregisteredCompanyReports?.items ?? []
      const psychologistReports = data?.psychologistReports ?? []

      const psychologistReportMap = new Map(psychologistReports.map(report => [report?.id, report]))

      const reportsNodes = await Promise.all(
        reportItems?.map(report => {
          const psychologistReport = psychologistReportMap.get(report?.id)
          return makeTableRow(report ?? null, psychologistReport?.victimName ?? '')
        })
      )
      setReports(reportsNodes.filter(report => report?.key))
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  const makeTableRow = async (
    report:
      | NonNullable<NonNullable<ReportsTableQuery['unregisteredCompanyReports']>['items']>[number]
      | null,
    name: string
  ) => {
    return {
      key: report?.id,
      name: [report?.id, name],
      closed: report?.closed,
      emailsSent: report?.emailsSent,
      createdAt: report?.createdAt,
    }
  }

  return (
    <ContentLayout header={<ContentLayout.Header title='Reports' />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Card>
          <Row gutter={16} style={{ padding: '24px' }}>
            <Col xs={24} sm={12} md={6}>
              <InputLabel>Emails sent</InputLabel>
              <br />
              <Switch onChange={() => setEmailsSent(!emailsSent)} />
            </Col>
          </Row>
        </Card>
        <Card hasNoPadding>
          <TableEnhanced
            loading={loading}
            columns={columns}
            dataSource={reports}
            pagination={false}
            style={{ minHeight: 300 }}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            pageSize={ROWS_PER_PAGE}
            current={page}
            total={data?.unregisteredCompanyReports?.totalCount}
            onChange={page => setPage(page)}
            showSizeChanger={false}
          />
        </Card>
      </Space>
    </ContentLayout>
  )
}

const InputLabel = styled.span`
  display: inline-block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
`

export default Reports
