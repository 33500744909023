import { useMutation } from '@apollo/client'
import { Link, useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Popconfirm, notification } from '@faceup/ui-base'
import { Plan } from '@faceup/utils'
import { graphql } from '../__generated__'

const mutations = {
  closeReportMutation: graphql(`
    mutation CloseReportMutation($input: CloseReportInput!) {
      closeReport(input: $input) {
        closedReport {
          id
          closed
        }
      }
    }
  `),
}

type Props = {
  closed: boolean | null
  emailsSent: boolean | null
  id: string
  company: { isVerified: boolean | null; billing: { plan: Plan | null } | null }
}

const CloseReportButton = ({ closed, emailsSent, id, company: { isVerified, billing } }: Props) => {
  const navigate = useNavigate()
  const [closeSchoolReport, { loading }] = useMutation(mutations.closeReportMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => navigate(routes => routes.reports()),
  })

  if (closed) {
    return null
  }

  if (!isVerified && billing?.plan !== Plan.Unregistered) {
    return (
      <>
        The institution is waiting for registration. Verify it on{' '}
        <Link to={routes => routes.schools()}>institution page</Link>.
      </>
    )
  }

  return (
    <Popconfirm
      title={<>Resolve{emailsSent ? null : <i>(E-mails were not sent!)</i>}</>}
      onConfirm={() =>
        closeSchoolReport({
          variables: {
            input: {
              reportId: id,
            },
          },
        })
      }
    >
      <Button loading={loading}>Close report</Button>
    </Popconfirm>
  )
}

export default CloseReportButton
