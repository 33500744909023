import { useApolloClient, useLazyQuery } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import type { Region } from '@faceup/utils'
import { useEffect, useState } from 'react'
import { graphql } from '../__generated__'

const query = {
  EmailDiscovery: graphql(`
    query EmailDiscoveryQuery($email: GraphQLString!) {
      region: emailDiscovery(email: $email)
    }
  `),
}

const REGION_KEY = 'region'

export const retrieveRegion = () => localStorage.getItem(REGION_KEY) as Region | null

const useRegion = () => {
  const client = useApolloClient()
  const [region, setRegion] = useState<string | null>()

  const [fetchEmailDiscovery, { loading }] = useLazyQuery(query.EmailDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
  })

  useEffect(() => {
    const storedRegion = retrieveRegion()

    if (storedRegion) {
      setRegion(storedRegion)
    }
  }, [])

  useEffect(() => {
    const storedRegion = retrieveRegion()

    if (region && region !== storedRegion) {
      localStorage.setItem(REGION_KEY, region)

      client.resetStore()
    }
  }, [region, client])

  const discoverByEmail = async (email: string) => {
    if (region) {
      return null
    }

    const { data } = await fetchEmailDiscovery({
      variables: { email },
    })

    if (!data?.region) {
      return null
    }

    setRegion(data.region)

    return data.region
  }

  return {
    region,
    setRegion,
    discoverByEmail,
    loading,
  }
}

export default useRegion
