import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { useNavigate, useParams } from '@faceup/router'
import { Button, ButtonCircle, ContentLayout, PageContentSkeleton } from '@faceup/ui'
import {
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  MoreOutlined,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  notification,
} from '@faceup/ui-base'
import { convertCountryToCountryName, convertLanguageToNativeLanguage } from '@faceup/utils'
import { useState } from 'react'
import RouteNotFound from '../../RouteNotFound'
import { graphql } from '../../__generated__'
import EditCompanyServiceModal from './EditCompanyServiceModal'

const { Title, Text } = Typography

const query = {
  AllPartnersQuery: graphql(`
    query AllPartnersQuery {
      partners(page: 0, rowsPerPage: 100000, verificationStatus: [Approved]) {
        totalCount
        items {
          id
          name
          ...EditCompanyService_partner
        }
      }
    }
  `),
  CompanyServiceDetailQuery: graphql(`
    query CompanyServiceDetailQuery($serviceId: CompanyServiceGlobalId!) {
      companyService(id: $serviceId) {
        id
        providerName
        countries
        providerUrl
        logoUrl

        notAvailablePartners {
          id
          name
        }

        allTranslations {
          id
          language
          default
          description
          categories
        }

        ...EditCompanyService_companyService
      }
    }
  `),
}

const mutations = {
  DeleteCompanyService: graphql(`
    mutation DeleteCompanyServiceMutation($input: DeleteCompanyServiceInput!) {
      deleteCompanyService(input: $input) {
        success
      }
    }
  `),
}

const CompanyServiceDetail = () => {
  const { id: serviceId } = useParams<'id'>()
  const navigate = useNavigate()
  const [isEdited, setIsEdited] = useState(false)

  const { loading, data } = useQuery(query.CompanyServiceDetailQuery, {
    variables: { serviceId: serviceId ?? '' },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
  })

  const { loading: partnersLoading, data: partnersData } = useQuery(query.AllPartnersQuery, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
  })

  const [deleteService] = useMutation(mutations.DeleteCompanyService, {
    onCompleted: () => navigate(routes => routes.services()),
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
  })

  if (loading && !data) {
    return <PageContentSkeleton />
  }

  const service = data?.companyService
  if (!service) {
    return <RouteNotFound />
  }

  const translations = service?.allTranslations ?? []

  const serviceCountries = (service.countries ?? [])
    .map(countryShortcut => convertCountryToCountryName(countryShortcut, 'en'))
    .join(' | ')

  const items = translations.map(translation => ({
    key: translation.id,
    label: convertLanguageToNativeLanguage(translation.language),
    children: (
      <Space direction='vertical' size='large' style={{ whiteSpace: 'pre-wrap', marginTop: 30 }}>
        {translation.description}
        {translation.categories.length > 0 && (
          <div>
            <Title level={4}>Categories</Title>
            <ul>
              {translation.categories.map(category => (
                <li key={category}>{category}</li>
              ))}
            </ul>
          </div>
        )}
      </Space>
    ),
  }))

  return (
    <ContentLayout header=''>
      <Card>
        <div>
          <Space direction='vertical' size='large'>
            <div>
              <Title level={3} style={{ marginBottom: 5 }}>
                {service?.providerName}
              </Title>
              <Text type='secondary' strong>
                {serviceCountries}
              </Text>
            </div>

            <a target='_blank' rel='noopener noreferrer' href={`https://${service.providerUrl}`}>
              {service.providerUrl}
            </a>
          </Space>

          <ImagePreview src={service?.logoUrl ?? ''} alt='Preview image' />
        </div>
        <Space size='large'>
          <Button type='submit' disabled>
            Send
          </Button>

          <Dropdown
            placement='bottomRight'
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Edit service',
                  onClick: () => setIsEdited(true),
                },
                {
                  key: 'delete',
                  label: 'Delete service',
                  onClick: () =>
                    deleteService({
                      variables: {
                        input: {
                          serviceId: service.id,
                        },
                      },
                    }),
                },
              ],
            }}
          >
            <ButtonCircle icon={<MoreOutlined />} />
          </Dropdown>
        </Space>
        <Tabs items={items} />
        <LimitedForm layout='vertical'>
          <Form.Item label='Not available partners'>
            <Select
              disabled
              mode='multiple'
              placeholder='Select partners which will not see this service'
              style={{ width: '100%' }}
              loading={partnersLoading}
              value={service?.notAvailablePartners?.map(partner => partner?.id ?? '') ?? []}
              options={partnersData?.partners?.items?.map(partner => ({
                label: partner?.name,
                value: partner?.id,
              }))}
            />
          </Form.Item>

          <Title level={4} style={{ marginTop: 30 }}>
            Contact
          </Title>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label='E-mail'>
                <Input disabled placeholder='E-mail' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Phone'>
                {/* TODO: Change to <InputPhone /> */}
                <Input type='tel' autoComplete='tel' disabled placeholder='Phone' />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label='Institution'>
            <Input disabled placeholder='Institution' />
          </Form.Item>
          <Form.Item label='Message'>
            <Input.TextArea rows={5} disabled placeholder='Message' />
          </Form.Item>

          <Button disabled type='submit'>
            Send
          </Button>
        </LimitedForm>
      </Card>

      <EditCompanyServiceModal
        service={service}
        visible={isEdited}
        partners={partnersData?.partners?.items ?? []}
        setVisible={setIsEdited}
      />
    </ContentLayout>
  )
}

const ImagePreview = styled.img`
  min-height: 50px;
  min-width: 100px;
  height: 50px;
  width: 100px;
  margin-left: 30px;
  margin-top: 15px;
  object-fit: contain;
`

const LimitedForm = styled(Form)`
  max-width: 700px;
`

export default CompanyServiceDetail
