import { useMutation } from '@apollo/client'
import { Button, Form, Modal } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { ChannelType, isLength, isNumeric } from '@faceup/utils'
import { TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import { graphql } from '../__generated__'

const mutations = {
  approveAutomatedHotlineMutation: graphql(`
    mutation ApproveAutomatedHotlineOrderMutation(
      $input: ApproveAutomatedHotlineOrderInput!
    ) {
      approveAutomatedHotlineOrder(input: $input) {
        success
      }
    }
  `),
  approveLiveHotlineMutation: graphql(`
    mutation ApproveLiveHotlineOrderMutation(
      $input: ApproveLiveHotlineOrderInput!
    ) {
      approveLiveHotlineOrder(input: $input) {
        success
      }
    }
  `),
}

const ApproveHotlineModal = ({
  reportSourceId,
  type,
  refetch,
}: {
  reportSourceId: string
  type: ChannelType.AutomatedHotline | ChannelType.LiveHotline
  refetch: () => void
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const form = useForm({
    initialValues: { phoneNumber: '' },

    validate: {
      phoneNumber: value => {
        if (
          !isNumeric(value, { no_symbols: true }) ||
          !isLength(value.trim(), {
            min: 5,
            max: 50,
          })
        ) {
          return 'Phone number should only contain digits with no spaces or special characters (search for E.164 notation)'
        }
        return null
      },
    },
  })

  const [approveAutomatedHotline] = useMutation(mutations.approveAutomatedHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      setModalOpen(false)
      refetch()
    },
  })

  const [approveLiveHotline] = useMutation(mutations.approveLiveHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      setModalOpen(false)
      refetch()
    },
  })

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Approve</Button>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title='Approve hotline mutation'
      >
        <Form
          form={form}
          customSubmitButtonText='Approve'
          onSubmit={async values => {
            const input = {
              phoneNumber: Number(values.phoneNumber),
              reportSourceId,
            }
            if (type === ChannelType.LiveHotline) {
              await approveLiveHotline({ variables: { input } })
              return true
            }
            if (type === ChannelType.AutomatedHotline) {
              await approveAutomatedHotline({
                variables: { input },
              })
              return true
            }
            return false
          }}
        >
          <Form.Item label='Phone number'>
            <TextInput {...form.getInputProps('phoneNumber')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ApproveHotlineModal
