import { useMutation } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Popconfirm, Space, notification } from '@faceup/ui-base'
import { Institution } from '@faceup/utils'
import { useState } from 'react'
import { graphql } from '../__generated__'

const mutations = {
  forceDeleteInstitutionMutation: graphql(`
    mutation ForceDeleteInstitutionMutation($input: ForceDeleteCompanyInput!) {
      forceDeleteCompany(input: $input) {
        success
      }
    }
  `),
}

type Props = {
  institutionId: string | null
  type: Institution | null
  realInstitution: boolean | null
  disabled?: boolean
}

const ForceDeleteInstitutionButton = ({
  institutionId,
  type,
  realInstitution,
  disabled,
}: Props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const navigate = useNavigate()
  const [deleteInstitution, { loading }] = useMutation(mutations.forceDeleteInstitutionMutation, {
    onError: error => {
      setLoadingButton(false)

      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      setLoadingButton(false)
      navigate(routes => (type === Institution.Company ? routes.companies() : routes.schools()))
    },
  })

  if (!institutionId) {
    return null
  }

  return (
    <Space>
      <Popconfirm
        title='All the data related to this institution (reports, reporting channels, institution, members and Chargebee profile if the company does not have invoices) will be deleted. This action cannot be undone. Use in case of duplicated company trial, demo companies etc. Are you sure you want to delete this institution?'
        onConfirm={() => {
          setLoadingButton(true)
          deleteInstitution({
            variables: { input: { companyId: institutionId, shouldForceDelete: true } },
          })
        }}
      >
        <Button
          disabled={disabled}
          variant='secondary'
          color='error'
          loading={loading || loadingButton}
        >
          Full institution deletion
        </Button>
      </Popconfirm>
      {realInstitution && (
        <Popconfirm
          title='Deleting only some data related to this institution (reports, reporting channels, members). This action cannot be undone. You should ideally use full institution deletion. Are you sure you want to delete this institution?'
          // currently is not working - disable button
          okButtonProps={{ disabled: true }}
          onConfirm={() => {
            setLoadingButton(true)
            deleteInstitution({
              variables: { input: { companyId: institutionId, shouldForceDelete: false } },
            })
          }}
        >
          <Button
            disabled={disabled}
            variant='secondary'
            color='error'
            loading={loading || loadingButton}
          >
            Delete institution
          </Button>
        </Popconfirm>
      )}
    </Space>
  )
}

export default ForceDeleteInstitutionButton
