import { useMutation } from '@apollo/client'
import { Button } from '@faceup/ui'
import { Typography, notification } from '@faceup/ui-base'
import { graphql } from '../__generated__'

const { Paragraph } = Typography

type Props = {
  motherId: string
  isOfflinePayment?: boolean
}

const mutations = {
  createPaymentUrlMutation: graphql(`
    mutation CreatePaymentUrlMutation($input: CreatePaymentUrlInput!) {
      createPaymentUrl(input: $input) {
        url
      }
    }
  `),
}

const PaymentLink = ({ motherId, isOfflinePayment }: Props) => {
  const [createPaymentUrl, { loading, data }] = useMutation(mutations.createPaymentUrlMutation, {
    variables: { input: { motherId, allowOfflinePaymentMethods: Boolean(isOfflinePayment) } },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  const paymentUrl = data?.createPaymentUrl?.url

  return (
    <>
      <Button
        variant='secondary'
        onClick={() => createPaymentUrl()}
        loading={loading}
        disabled={Boolean(paymentUrl)}
      >
        Payment URL {isOfflinePayment ? '💳 & 🏦' : '💳'}
      </Button>
      {paymentUrl && <Paragraph copyable>{paymentUrl}</Paragraph>}
    </>
  )
}

export default PaymentLink
