import { useMutation } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import CompanyServiceEditor from './CompanyServiceEditor'

const fragments = {
  EditCompanyService_companyService: graphql(`
    fragment EditCompanyService_companyService on CompanyService {
      id
      ...CompanyServiceEditor_companyService
    }
  `),
  EditCompanyService_partner: graphql(`
    fragment EditCompanyService_partner on PartnerInstitution {
      id
      ...CompanyServiceEditor_partner
    }
  `),
}

const mutation = {
  EditCompanyService: graphql(`
    mutation EditCompanyService($input: EditCompanyServiceInput!) {
      editCompanyService(input: $input) {
        service {
          id
          ...EditCompanyService_companyService
        }
      }
    }
  `),
}

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  service: FragmentType<typeof fragments.EditCompanyService_companyService>
  partners: FragmentType<typeof fragments.EditCompanyService_partner>[]
}

const EditCompanyServiceModal = ({
  service: _service,
  visible,
  setVisible,
  partners: _partners,
}: Props) => {
  const service = getFragmentData(fragments.EditCompanyService_companyService, _service)
  const partners = getFragmentData(fragments.EditCompanyService_partner, _partners)
  const [editCompanyService, { loading }] = useMutation(mutation.EditCompanyService, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: () => setVisible(false),
  })

  return (
    <CompanyServiceEditor
      loading={loading}
      service={service}
      visible={visible}
      partners={partners}
      setVisible={setVisible}
      saveChanges={newService => {
        editCompanyService({
          variables: {
            input: {
              serviceId: service.id,
              ...newService,
            },
          },
        })
      }}
    />
  )
}

export default EditCompanyServiceModal
