import { useMutation } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { notification } from '@faceup/ui-base'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import CompanyServiceEditor from './CompanyServiceEditor'

const fragments = {
  CreateCompanyService_partner: graphql(`
    fragment CreateCompanyService_partner on PartnerInstitution {
      id
      ...CompanyServiceEditor_partner
    }
  `),
}

const mutation = {
  CreateCompanyService: graphql(`
    mutation CreateCompanyService($input: CreateCompanyServiceInput!) {
      createCompanyService(input: $input) {
        service {
          id
        }
      }
    }
  `),
}

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  partners: FragmentType<typeof fragments.CreateCompanyService_partner>[]
}

const CreateCompanyServiceModal = ({ visible, setVisible, partners: _partners }: Props) => {
  const partners = getFragmentData(fragments.CreateCompanyService_partner, _partners)
  const navigate = useNavigate()

  const [createCompanyService, { loading }] = useMutation(mutation.CreateCompanyService, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      setVisible(!visible)
      navigate(routes => routes.service({ id: data.createCompanyService?.service?.id ?? '' }))
    },
  })

  return (
    <CompanyServiceEditor
      loading={loading}
      visible={visible}
      setVisible={setVisible}
      partners={partners}
      service={null}
      saveChanges={newService => {
        createCompanyService({
          variables: {
            input: newService,
          },
        })
      }}
    />
  )
}

export default CreateCompanyServiceModal
